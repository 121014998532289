$white: #fff;

$font-principal: "faricynew-regular", sans-serif;
$fontWeight-principal: 500;
$font-headers: "faricynew-medium", sans-serif;
$fontWeight-headers: 500;

$font-titulares: "faricynew-regular", serif;
$color-background: $white;
$color-principal: #d8252c;
$color-secundario: #636363;
$color-obscuro: #333;
$color-suave: #eee;
$color-highlight: #00091e;
$color-texto: $color-obscuro;
$color-headers: $color-obscuro;

//Boton Principal
$borderRadius-boton: 4px;
$font-boton: $font-principal;
$fontWeight-boton: 600;
$color-boton: $color-principal;
$color-boton-texto: $white;
$color-boton-border: 2px solid $color-principal;

//  hover
$color-boton-hover: $color-secundario;
$color-boton-texto-hover: $white;
$color-boton-border-hover: 2px solid $color-secundario;

//Boton Secundario
$font-boton-secundario: $font-principal;
$color-boton-secundario: $color-principal;
$color-boton-secundario-texto: $white;
$color-boton-secundario-border: 2px solid $white;
//  hover
$color-boton-secundario-hover: $white;
$color-boton-secundario-texto-hover: $color-principal;
$color-boton-secundario-border-hover: 2px solid $color-principal;

//Boton soft

$font-boton-soft: $font-principal;
$color-boton-soft: $color-suave;
$color-boton-soft-texto: $color-principal;
$color-boton-soft-border: 2px solid $color-suave;
//  hover
$color-boton-soft-hover: $color-suave;
$color-boton-soft-texto-hover: $color-principal;
$color-boton-soft-border-hover: 2px solid $color-principal;

//seccion highlight
$color-highlight: $color-highlight;
$color-highlight-header: #301f0d;
$color-highlight-text: #301f0d;

//seccion highlight-2
$color-highlight2: $color-principal;
$color-highlight2-header: $color-suave;
$color-highlight2-text: $white;

//tamaño de fuente
$fontSize-content: 16px;

html {
    -webkit-font-smoothing: antialiased !important;
}
body {
    font-family: $font-principal;
    font-weight: $fontWeight-principal;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 1.3em;
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-headers;
    font-family: $font-headers;
    font-weight: $fontWeight-headers;
    line-height: 1.5em;
}

.subtitle {
    font-family: $font-titulares;
    line-height: 1.2em;
}
.txt-center {
    text-align: center;
}

//Color del SPLASH
#splash h1,
#splash h2,
#splash h3,
#splash h4 {
    color: $color-principal;
}

h2.border {
    border-top: none;
}

.rmsPax th {
    font-size: inherit;
}

//Tamaño de cuerpos de texto home
body.landing-home .content-landing {
    font-size: $fontSize-content;
}

.small,
.hotCat,
.smallv,
.hiLits td,
.rooInf,
.intro,
.hotDat,
ul,
ol,
dl,
#rooAme a,
div.intCont,
table.flightsec,
#divFlightDetail .cajaErr,
#flightSelected,
#prefix,
#specialAssist,
#SearchBox1_ddlCabin,
.formRow input {
    font-size: 14px;
}

.red {
    font-size: 14px;
}

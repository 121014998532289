//Header
$font-header-link: $font-principal;
$color-header-background: $white;
$color-header-gradient: none;
$color-header-text: #333;

$color-header-text-hover: $color-obscuro;

$color-headermenu-background: $color-principal;
$color-headermenu-text: $color-principal;
$color-headermenu-text-hover: $color-secundario;
//NavBar
$color-navbar-background: $color-secundario;
$color-navbar-text: $white;

$color-headermenunavbar-background: $color-principal;
$color-headermenunavbar-text: $white;
$color-headermenunavbar-text-hover: #ffffffb6;

#header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-header-background;
}
#header a {
  color: $color-header-text;
  font-family: $font-header-link;
  line-height: 20px;
  font-weight: 600;
  &:hover {
    color: $color-header-text-hover;
    text-decoration: underline;
  }
}
.headermenu-navbar {
  display: flex;
  width: 100%;
}

.header-container,
.headermenu-navbar-container {
  display: flex;
  width: 100%;
  max-width: 1140px;
  margin: auto;
  padding-bottom: 0px;
}

#header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#header li {
  list-style: none;
}

.header-menu {
  display: flex;
  gap: 38px;
}
.header-menu li a {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-family: $font-headers !important;
  font-weight: $fontWeight-headers;
  font-size: 14px;
}
.headermenu-navbar {
  overflow-x: auto;
  scroll-behavior: smooth;
}
.headermenu-navbar-container ul {
  width: 100%;
  gap: 5px;
}
.headermenu-navbar {
  li a,
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    color: rgba(34, 34, 34, 0.6) !important;
    height: 55px;
    justify-content: space-between;
    border: 0;
    background: transparent;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    padding-bottom: 15px;
  }
  li a {
    white-space: nowrap;
  }
  .font-icons-mj {
    font-size: 20px;
  }
}
.header-sitename,
#displayMenu,
.container.yay {
  display: none;
}
.menu-mobile {
  display: flex;
  height: 68px;
  align-items: center;
  background-color: $color-suave;
  padding: 0px 30px;
  border-radius: 0px 0px 12px 12px;
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  -ms-border-radius: 0px 0px 12px 12px;
  -o-border-radius: 0px 0px 12px 12px;
}
.menu-mobile .bar1,
.menu-mobile .bar2,
.menu-mobile .bar3 {
  width: 30px;
  height: 3px;
  background-color: $color-header-text;
  margin: 5px 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
input:checked + label .bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 5px);
  -ms-transform: rotate(-45deg) translate(-5px, 5px);
  transform: rotate(-45deg) translate(-5px, 5px);
  -moz-transform: rotate(-45deg) translate(-5px, 5px);
  -o-transform: rotate(-45deg) translate(-5px, 5px);
}
input:checked + label .bar2 {
  opacity: 0;
}
input:checked + label .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  -ms-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
  -moz-transform: rotate(45deg) translate(-6px, -6px);
  -o-transform: rotate(45deg) translate(-6px, -6px);
}
input:checked + label + .menuProducts {
  display: block;
  max-height: 80vh;
  z-index: 20;
  opacity: 1;
  width: 100%;
  -webkit-box-shadow: -1px 10px 17px -6px rgba(158, 158, 158, 0.97);
  -moz-box-shadow: -1px 10px 17px -6px rgba(158, 158, 158, 0.97);
  box-shadow: -1px 10px 17px -6px rgba(158, 158, 158, 0.97);
  top: 80px;
}
.whatsapp-color {
  color: #45c656;
}

//Cuando AGREGAS Fixed a los headers

@media (min-width: 576px) {
}
@media (min-width: 767px) {
}
@media (min-width: 992px) {
  .home #header.header-fixed {
    position: fixed;
    z-index: 50;
    background: #ffffffaa;
    backdrop-filter: blur(10px);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    //se oculta navbar
    div.headermenu-navbar {
      display: none !important;
    }
    .header-logo img {
      height: 32px;
    }
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .menuProducts {
    display: none;
    position: absolute;
    right: 0px;
    background-color: #fff;
  }
  .container.yay {
    display: block;
    width: auto;
    background: transparent;
    padding: 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .menuProducts {
    display: none;
    position: absolute;
    background-color: $color-header-background;
  }
  .header-menu {
    flex-direction: column;
    gap: 0px;
  }
  .header-menu li {
    padding: 12px 8px;
    text-align: center;
  }
  .header-menu li:hover {
    background-color: $color-principal;
    a {
      color: $white;
    }
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 1399.98px) {
}
@media print {
  .headermenu-navbar {
    display: none;
  } /* Aquí irían tus reglas CSS específicas para imprimir */
}

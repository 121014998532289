@media (min-width: 544px) {
    .hotel-offer-item {
        position: relative;
        min-height: 1px;
        padding-right: 0px;
        padding-left: 0px;
        float: left;
        width: 50%;
    }
    .hotel-offer-item:nth-child(odd) {
        clear: both;
    }
}
@media (min-width: 768px) {
    .hotel-offer-item {
        float: left;
        width: 33.33333%;
    }
    .hotel-offer-item:nth-child(odd) {
        clear: none;
    }
    .hotel-offer-item:nth-child(3n + 1) {
        clear: both;
    }
}
@media (min-width: 992px) {
    .hotel-offer-item {
        float: left;
        width: 25%;
    }
    .hotel-offer-item:nth-child(3n + 1) {
        clear: none;
    }
    .hotel-offer-item:nth-child(4n + 1) {
        clear: both;
    }
}
@media (min-width: 544px) {
    #promoTres {
        margin-left: 25%;
    }
}
@media (min-width: 768px) {
    div.controls-prev {
        z-index: 1;
    }
    .bookerBox {
        position: relative;
    }
    div.pt-customJqueryUi .ui-widget-content {
        z-index: 3;
    }
}
@media (min-width: 992px) {
    .promoBox {
        float: left;
        width: 33.33333%;
    }
    #promoTres {
        margin-left: 0%;
    }
}
@media (max-width: 991px) {
    div.slider-inner ul li .titleSlide .titleOffer .titleOfferTxt {
        font-size: 2.3em;
    }
    #itinerary {
        float: none;
        width: 90%;
        overflow-x: scroll;
    }
}
@media (max-width: 767px) {
    div.slider-inner ul li .titleSlide {
        width: 94%;
        left: 3%;
        top: 10px;
        right: 3%;
    }
    div.slider-inner ul li .titleSlide .titleOffer .titleOfferTxt {
        font-size: 2em;
    }
    .promoBox .slider-inner ul li .titleSlide .titleOffer {
        bottom: 0px;
    }
}
@media (max-width: 543px) {
    div.slider-inner ul li .titleSlide .titleOffer {
        display: block;
        width: 100%;
    }
    div.slider-inner ul li .titleSlide .titleOffer .titleOfferTxt {
        font-size: 1.75em;
    }
    div.slider-inner ul li .titleSlide .titleBtn {
        display: block;
        width: 100%;
        margin-top: 15px;
    }

    div.pt-customJqueryUi .ui-datepicker.ui-datepicker-multi {
        max-width: 100%;
        left: 0 !important;
    }
}

@media (min-width: 768px) {
    #footer .footerLinks-top:after,
    #footer .footerLinks-top:before {
        content: " ";
        display: table;
        clear: both;
    }
    #footer .footerLinks-top .footerCol {
        float: left;
        width: 33.3333%;
        padding: 0 10px;
        margin-bottom: 0;
    }
    // #footer .footerLinks-top .border {
    //   width: 100%;
    //   height: 1px;
    //   background-color: $white;
    // }
    #footer .footerLinks-bottom {
        margin-top: 40px;
    }
    #footer ul.ftBox-links.legalLinks li {
        display: inline-block;
        padding-right: 16px;
        /*border-right: 1px solid $white;*/
        margin-right: 14px;
        margin-bottom: 0;
    }
    #footer ul.ftBox-links.legalLinks li:last-child {
        padding-right: 0;
        border-right: none;
        margin-right: 0;
    }
}
@media (min-width: 992px) {
    #footer .footerLinks-top .footerCol {
        min-height: 111px;
    }
    #footer .footerLinks-top .border {
        max-width: 280px;
        margin: 0 auto;
    }
    // #footer .footerLinks-top .border-left {
    //   border-left: 1px solid $white;
    // }
    // #footer .footerLinks-top .border-right {
    //   border-right: 1px solid $white;
    // }
}
@media (min-width: 1400px) {
    #footer .footerBox {
        padding-left: 0;
        padding-right: 0;
    }
}
@media (min-width: 992px) {
    div#Content {
        width: 100%;
        max-width: 1400px;
    }
    #Content div#left {
        float: left;
        width: 20%;
    }
    #Content div#main {
        float: right;
        width: 77%;
        padding-left: 0;
        margin-left: 1%;
    }
    #Content div#main.home {
        float: left;
        width: 660px;
    }
}

//Footer
$color-footer-background: $color-highlight;
$color-footer-text: $white;
$color-footer-headers: $white;
.footerBox {
  margin-left: 0px;
  margin-right: 0px;
  max-width: 1400px;
  margin: 0 auto;
}
.footerBox:after {
  content: "";
  display: table;
  clear: both;
}
footer,
#footer {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-footer-headers;
  }
}

#footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#footer ul li {
  padding: 0;
  margin: 8px 0;
}

#footer {
  text-align: left;
}
#footer ul.ftBox-links {
  padding: 0;
  list-style: none;
  font-size: 12px;
}
#footer ul.ftBox-links li {
  display: inline-block;
  padding: 0 15px;
}

#footer {
  color: $color-footer-text;
  background-color: $color-footer-background;

  font-family: $font-principal;
}
#footer a {
  font-family: $font-principal;
  color: $color-footer-text;
  font-size: 1rem;
}
#footer a:hover {
  color: $color-footer-text;
  text-decoration: underline;
}
#footer img {
  max-width: 100%;
}
#footer .footerBox-top {
  padding-top: 60px;
  padding-bottom: 10px;
}
#footer .footerBox-bottom {
  padding-top: 10px;
  padding-bottom: 30px;
}
#footer .footerBox {
  padding-left: 15px;
  padding-right: 15px;
}
#footer .footerCol {
  margin-bottom: 30px;
}
#footer h3 {
  font-size: 18px;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 20px;
}
#footer h3 svg {
  vertical-align: middle;
}
#footer p {
  font-family: $font-principal;
  line-height: 160%;
  margin-bottom: 0;
  margin-left: 3% !important;
}
#footer ul.ftBox-links li {
  font-size: 14px;
  font-weight: 100;
  line-height: 120%;
}
#footer ul.socialLinks {
  line-height: 1;
}
#footer ul.socialLinks li {
  display: inline-block;
  padding: 0 4px;
  margin: 0 4px;
  vertical-align: middle;
}
#footer ul.socialLinks .social-icon {
  display: block;
  background-color: $white;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  padding: 8px;
  color: $color-footer-background;
}
#footer ul.socialLinks .social-icon:hover path {
  fill: $color-secundario;
}
#footer ul.legalLinks li {
  display: block;
  padding: 0;
  margin-bottom: 15px;
}
#footer ul.legalLinks li:last-child {
  margin-bottom: 0;
}
#footer .footer-red {
  background: #ce2936;
  max-width: 100% !important;
}
#footer .icons {
  width: 16px;
  margin-right: 1.5rem !important;
}
#resultados-page #footer a,
#habitaciones-page #footer a,
#detalle-reservacion-paquetes-page #footer a,
#hoteles-page #footer a,
#detalles-reservacion-page #footer a {
  font-size: 1.4rem !important;
}

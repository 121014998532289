// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,600;1,300&family=Source+Sans+Pro&display=swap");
$f-font-regular: "faricynew-regular" !default;
$f-font-medium: "faricynew-medium" !default;
$f-font-bold: "faricynew-bold" !default;
$font-path: "https://b2b2c.cdnpt.com/mundojoven/fonts" !default;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Young+Serif&display=swap");
@font-face {
    font-family: "#{$f-font-regular}";
    src: url("#{$font-path}/#{$f-font-regular}.eot?y8qxcg");
    src:
        url("#{$font-path}/#{$f-font-regular}.eot?y8qxcg#iefix") format("embedded-opentype"),
        url("#{$font-path}/#{$f-font-regular}.ttf?y8qxcg") format("truetype"),
        url("#{$font-path}/#{$f-font-regular}.woff?y8qxcg") format("woff"),
        url("#{$font-path}/#{$f-font-regular}.svg?y8qxcg##{$f-font-regular}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "#{$f-font-medium}";
    src: url("#{$font-path}/#{$f-font-medium}.eot?y8qxcg");
    src:
        url("#{$font-path}/#{$f-font-medium}.eot?y8qxcg#iefix") format("embedded-opentype"),
        url("#{$font-path}/#{$f-font-medium}.ttf?y8qxcg") format("truetype"),
        url("#{$font-path}/#{$f-font-medium}.woff?y8qxcg") format("woff"),
        url("#{$font-path}/#{$f-font-medium}.svg?y8qxcg##{$f-font-medium}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "#{$f-font-bold}";
    src: url("#{$font-path}/#{$f-font-bold}.eot?y8qxcg");
    src:
        url("#{$font-path}/#{$f-font-bold}.eot?y8qxcg#iefix") format("embedded-opentype"),
        url("#{$font-path}/#{$f-font-bold}.ttf?y8qxcg") format("truetype"),
        url("#{$font-path}/#{$f-font-bold}.woff?y8qxcg") format("woff"),
        url("#{$font-path}/#{$f-font-bold}.svg?y8qxcg##{$f-font-bold}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

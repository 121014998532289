$color-background-landing: #ffffff;
$color-text-landing: #000000;
//Resets y Generales
*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    background: $color-background;
}
// body.home.landing-home {
//     background: $color-background-landing;
//     color: $color-text-landing;
// }
#Content {
    background: transparent;
    border: none;
}

button,
input,
select,
textarea {
    border: 1px solid #e5e5e5;
    background: $white;
    padding: 3px 5px;
    border-radius: 3px;
}

#hsidebar #contact {
    border: 1px solid #e5e5e5;
    background: #fff;
}

.alert-base,
.error-higLit,
.error-higLit .schedule,
.rate-group-price .warning-message,
.warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    border-radius: 3px;
}
#cajaErr,
#divError,
#divErrorFlight,
.cajaErr,
.divErr {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    border-radius: 3px;
}
.divErr h3 {
    color: inherit;
    margin: 0.5em 0;
}
.leg {
    padding: 0;
}

//Summary
#Content #summary h2,
#hsidebar h2,
#left #ctrl h2,
#left .banNom h4,
#left .sidbar h4,
#left h3,
#reserveinfo h2,
.booker-title,
.sidbar h3,
.sidebar-title {
    background: rgba(94, 106, 113, 0.9);
    color: rgba(255, 255, 255, 0.8);
}

#HotelName,
#HotelName:focus {
    background: #fff;
    border-color: #e5e5e5;
}
#left .module li a:hover {
    background: #efefef;
}
table.rates .ratedet .padding a.botAzu {
    margin-top: -3px;
}
table.rates td,
table.rates td.avg,
table.rates td.right {
    background: #fff;
}
.airline .icon,
.airline .ptw-menu .ap_booker_tab,
.airline div#agencyBar .agencyOption .caret,
.airlogo .icon,
.airlogo .ptw-menu .ap_booker_tab,
.airlogo div#agencyBar .agencyOption .caret,
.ptw-menu .airline .ap_booker_tab,
.ptw-menu .airlogo .ap_booker_tab,
.ptw-menu tr.flrow td img.ap_booker_tab,
div#agencyBar .agencyOption .airline .caret,
div#agencyBar .agencyOption .airlogo .caret,
div#agencyBar .agencyOption tr.flrow td img.caret,
tr.flrow td .ptw-menu img.ap_booker_tab,
tr.flrow td div#agencyBar .agencyOption img.caret,
tr.flrow td img.icon {
    mix-blend-mode: multiply;
}
.currPage,
.paginas a.currPage {
    background: $color-principal;
}
.paginas a,
.paginas a:link,
.paginas a:visited {
    background: #fff;
}
.pager .paginas a:hover,
.paginas a:hover {
    background: #efefef;
    color: $color-principal;
}
#tabs a:hover,
#tabs span:hover {
    background: #efefef;
}
.inter h3 {
    margin-bottom: 5px;
}
.inter h3:after {
    content: "";
    display: table;
    clear: both;
}
.inter h3 a.smallv {
    float: right;
    padding: 0px 5px;
}
.pager .paginas a,
.paginas a {
    background: #fff;
}
.addnight-box .pt-icon-info-circle:hover {
    color: $color-principal;
}
.addnight-box .late-options {
    border-color: #e5e5e5;
}
.addnight-box .option:first-of-type {
    border-bottom-color: #e5e5e5;
}
.addnight-box .options-box .selected {
    background: #efefef;
}
.addnight-box .latewarning .late {
    color: $color-principal;
}

.WhaIs,
.WhaIs.smallv {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    padding: 10px 10px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
#MapContainer table#tblMap,
#MapContainer table#tblMap table.tableMap {
    width: 100% !important;
}
.rates th {
    background: #fff;
}
.rates a.botAzu {
    margin-top: 0px;
}
.list-best-rates .selected-leg {
    background: #efefef;
}
div.cflt {
    background: transparent;
    border-color: #e5e5e5;
}
div.cflt h3 {
    color: #333;
    background: #fff;
}
tr.travDat td {
    border-bottom-color: #e5e5e5;
}
.schedule:hover {
    background: #efefef;
}
#ConTit table#headList + div {
    height: auto !important;
}
#modTab h3,
.bluebox h3 {
    background: #fff;
    border: none;
}
#itineraryContainer tr.banNom td,
#resFlight tr.banNom td,
.bluebox {
    background: #fff;
    border: 1px solid #e5e5e5;
}

#divPackageInfo {
    margin-bottom: 10px;
}
table.pack {
    border: none;
    background: #fff;
}
table.pack tr.flrow,
table.pack tr.roomsrow + tr {
    background: #fff;
}
#roomsContainer h3,
.roomsContainer h3 {
    color: $color-principal;
}
td.rooTit {
    border-bottom-color: #e5e5e5;
}
.amenities {
    border-left-color: #e5e5e5;
}
.bread,
td.bread {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
#helpleft .hotsidbar li a,
#left .hotsidbar li a {
    color: $color-principal;
}
#helpleft .hotsidbar li a.active,
#helpleft .hotsidbar li a:hover,
#left .hotsidbar li a.active,
#left .hotsidbar li a.active:hover,
#left .hotsidbar li a:hover,
#lhelpeft .hotsidbar li a.active:hover {
    background: #efefef;
}
#helpleft .hotsidbar li,
#left .hotsidbar li {
    border-bottom-color: #e5e5e5;
}
#helpleft .hotsidbar ul,
#left .hotsidbar ul {
    border-top-color: #e5e5e5;
}
#ActMain h3 {
    color: $color-principal;
}
#ActSidebar .module {
    background: #fff;
    border-radius: 0px;
    border: none;
}
#ActSidebar .module h2 {
    background: rgba(94, 106, 113, 0.9);
    color: rgba(255, 255, 255, 0.8);
    text-shadow: none;
}
div#photos img {
    border: none;
}
div#ZoomBox {
    border: solid #fff 7px;
}
div#ZoomBox #ZoomCapDiv {
    background: #fff !important;
    padding: 17px 0 10px;
}
.banres {
    border-top: 1px solid #e5e5e5;
}
.floatleft h1 {
    width: auto;
}
#MapContainer #map_canvas {
    width: 100% !important;
}
#modTab #divPax {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    border-radius: 3px;
    margin: 5px;
}
#modTab #divPax #parPax {
    margin: 0;
}
#activDetail {
    background: none;
    padding-bottom: 0;
    border: none;
}
#optionList.ActivOpt {
    padding: 10px 20px 5px;
    margin-top: 0;
    text-shadow: none;
    border: 1px solid #e5e5e5;
    background: #fff;
}
#modTab a#showSearchBoxLink {
    background: none;
}
#modTab a#showSearchBoxLink:before {
    font-family: "ptw-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e612";
    font-size: 19px;
    color: $color-principal;
    z-index: 0;
    position: absolute;
    margin-left: -22px;
    margin-top: 2px;
}
.ActivOpt {
    border: 1px solid #e5e5e5;
    background: #fff;
    text-shadow: none;
}
.actRates td,
.optionItem {
    border-top-color: #e5e5e5;
}
.optionItem h3 {
    border-top: none;
}
.ptw-field-selectEndOffice .ptw-field-radio {
    width: auto;
    display: inline;
}
#ptw-container .ptw-car-time select {
    width: 100%;
}
.carInfo {
    width: auto;
}
.carInfo .price {
    float: right;
}
#cars .rates th,
#cars td.left,
#cars td.ratedet {
    border-bottom-color: #e5e5e5;
}
.rates .icon-offer {
    line-height: 0.6em;
}
.price {
    float: none;
    line-height: normal;
    width: auto;
}
.module .iconList li {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background: none;
    margin: 0;
}
.module li input {
    margin: 0 4px 0 15px;
}
.modal-footer {
    background-color: #fff;
    border-top-color: #e5e5e5;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.listViewport {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-top: 1px solid #e5e5e5;
}
.mark,
.tag,
span.filter,
span.filter:hover {
    border-color: $color-principal;
    background: #efefef;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#appliedFilters span:hover {
    border-color: $color-principal;
}
a.filter:hover {
    color: inherit;
    text-decoration: none;
}
a:hover .cross {
    background-position: -181px -807px;
}
.rates tr:hover td {
    background: #efefef;
}
#detTar th,
#tarLis th {
    background: #fff;
    border-top-color: #e5e5e5;
    border-bottom-color: #e5e5e5;
    padding: 4px 0;
}
#detPop,
.detPop {
    background: #fff;
    border-color: #e5e5e5;
}
#tabPop {
    border: none;
    line-height: 24px;
    font-size: 14px;
    color: $color-principal;
}
#detPop h2,
#detPop h3 {
    color: $color-principal;
}
#ctrl {
    background: #fff;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: 1px solid #e5e5e5;
}
#left #ctrl h2 {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.innerEngine {
    border-radius: 0;
}
#bookerComplete,
#modTab {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#cruiseContent .alert-success {
    border: 1px solid #e5e5e5;
    background: #fff;
    color: inherit;
}
.table-bordered {
    border-color: #e5e5e5;
}
.table-bordered td + td,
.table-bordered td + th,
.table-bordered th + td,
.table-bordered th + th {
    border-left-color: #e5e5e5;
}
.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
    background-color: transparent;
}
.table tbody tr:hover td,
.table tbody tr:hover th {
    background-color: transparent;
}
#cruiseMain .shipAm .span3 {
    width: 30%;
}
#cruiseMain .accordion-inner .span5 {
    width: auto;
}
#cruiseTabs a {
    color: $color-principal;
}
#cruiseTabs a:hover {
    background: #efefef;
}
#cruiseTabs a.active {
    border-color: #e5e5e5;
    border-bottom: none;
    color: #555555;
}
#cruiseTabs a.active:hover {
    background: white;
}
.gal_spotlight {
    background: transparent;
}
.gal_carouselContainer,
.gal_spotlightDescription {
    background-color: #fff;
}
.gal_carouselContainer,
.gal_spotlightDescription {
    background-color: #fff;
}
.gal_carouselContainer a.gal_arrow:hover {
    background: #efefef;
}
#cabnSel .active strong,
#cabnSel a:hover strong,
.cabnSel .active strong,
.cabnSel a:hover strong {
    color: $color-principal;
}
.accordion-group {
    border-color: #e5e5e5;
}
.cabin-rate-menu {
    border-bottom-color: #e5e5e5;
}
.cabin-menu-options .triangle:before {
    border-bottom-color: #e5e5e5;
}
.cruiseRates th {
    border-bottom-color: #e5e5e5;
}
.cruiseRates td {
    border-bottom-color: #e5e5e5;
}
.thumbnail {
    border-color: #e5e5e5;
}
a.thumbnail:hover {
    border-color: $color-principal;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
}
#selectedCabin {
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-top-color: #e5e5e5;
}
#camCount .count {
    color: $color-principal;
}
#deckplan,
#legendul {
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #e5e5e5;
}
#stateroomsList tr.active td {
    background: #efefef;
}
.noCam {
    border: 1px solid #e5e5e5;
    background: #fff;
    color: $color-principal;
}
#stateroomsList tr.active .noCam,
#stateroomsList tr:hover .noCam,
.reqPax .noCam {
    border-color: #e5e5e5;
    background: white;
    color: $color-principal;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#innerSel .cabnSel {
    margin: 0 0 0 200px;
}
#selectedCabin .btn-group {
    margin: 15px 0 0 0;
}
#stateroomsList tr:hover td {
    background: #efefef;
    color: $color-principal;
}
.product-sidebar {
    margin-top: 20px;
}
.product-sidebar .sidebar-title {
    text-shadow: none;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
}
.product-sidebar .sidebar-module {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 0;
}
.sidbar {
    border: none;
}
.sidbar .booker-title,
.sidbar h3 {
    text-shadow: none;
    font-size: 15px;
    font-weight: bold;
    border-bottom: none;
    padding: 5px 3px;
}
.sidbar ul a:hover {
    background: #efefef;
}
.sidbar .ap_slider {
    margin: 0 0 1em;
}
.sidbar .ap_slider .ap_slider-duration {
    margin: 0 0.7em 1em;
}
.innerList li:hover {
    background: #efefef;
    color: $color-principal;
}
.circuit-wrap {
    border: none;
    border-bottom: 1px solid #e5e5e5;
}
.circuit-wrap h2.caps {
    font-size: 18px;
    font-weight: bold;
    text-transform: none;
    font-variant: normal;
}
.circuit-wrap h2.caps a:hover {
    text-decoration: none;
}
.img_3x2 a {
    border-color: #e5e5e5;
}
.priceDetail li.promo-text {
    background: #efefef !important;
}
.priceDetail li.promo-text:after {
    border-top-color: #efefef;
}
#modsea {
    margin-top: 0;
    border: none;
    border-radius: 0;
}
#ctrl {
    border-color: #e5e5e5;
}
#bookerComplete,
.product-sidebar .circuits-searchbox #ctrl {
    background: #fff;
}
#modTabCircuits .field {
    text-align: right;
}
.slats li {
    border-bottom-color: #e5e5e5;
}
.callAction {
    background: #fff;
    border-color: #e5e5e5;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.nav-tabs {
    border-bottom-color: #e5e5e5;
}
.sidebar-list li {
    border-bottom-color: #e5e5e5;
    border-top: none;
}
.nav-tabs {
    background: none;
}
div.orbit > img {
    width: 100%;
}
.article {
    border-bottom-color: #e5e5e5;
}
.gray-block {
    background-color: #fff;
}
.well {
    border-color: #e5e5e5;
    background-color: #fff;
}

.table-price-list th,
.table tbody tr:hover th {
    background: #fff;
}
.table tbody tr:hover td {
    background: #efefef;
}
.green-border {
    border-color: #e5e5e5;
}
.rounded-wrapper {
    border-color: #e5e5e5;
    border-radius: 0;
}

.table-price-list td {
    border-top-color: #e5e5e5;
}
.table-price-list .selected-column {
    background: transparent;
}
#bestAirContainer .bestAirList.viewport {
    width: auto;
}
#bestAirlines {
    border-color: #e5e5e5;
}
#bestAirlines .bestAirLeft {
    background: #fff;
    border-right-color: #e5e5e5;
    border-radius: 0;
}
#bestAirlines .nonstopRate,
#bestAirlines .stopsRate {
    color: $color-principal;
}
#bestAirlines .bestAirline:hover,
#bestAirlines.package #bestAirContainer .bestAirItem.selected,
#bestAirlines.package #bestAirContainer .bestAirItem:hover {
    background: #efefef;
}
#bestAirlines .allflights:hover,
#bestAirlines .nonstopRate:hover,
#bestAirlines .stopsRate:hover {
    color: $color-principal;
    background-color: #efefef;
}
#bestAirlines .paymentRate.payPromo {
    color: inherit;
}
#bestAirlines .paymentRate.payPromo .ptw-icon {
    font-family: inherit;
}
#bestAirlines .paymentRate.payPromo .ptw-icon:before {
    font-family: "ptw-icons";
}
#modTabCircuits .glyphicons-icon {
    width: 35px;
    margin-top: -12px;
}
#modTabCircuits h3,
#modTabCircuits p {
    text-align: left;
}
#bestAirContainer {
    border: none;
}
#bestAirContainer .overview {
    margin-top: 0;
}
#divAppliedFilter {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
#conTit {
    border: none;
    border-top: 1px solid #e5e5e5;
    background: #fff;
}
#flightSelected.yui-module,
#tblFligts .yui-module {
    border: 1px solid #e5e5e5;
    background: #fff;
}
#resFlight tr.light td,
.escala {
    background: rgba(255, 255, 255, 0.5);
}
#resFlight tr.light td .escala,
.escala .escala {
    background: none;
}
table.roomList td strong {
    color: $color-principal;
}
.blueboxR h4 {
    background: #fff;
}
table.roomList {
    border-bottom-color: #e5e5e5;
}
table.roomList td {
    border-top-color: #e5e5e5;
}
#summary .item {
    border-bottom: none;
    border-top-color: #e5e5e5;
    background-color: #fff;
}
.well {
    border: 1px solid #e5e5e5;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 4px 8px;
}
img#imgLogo {
    border-color: #e5e5e5;
}
#addon_content {
    border-color: #e5e5e5;
}
#addon_content .spec {
    float: right;
}
#addon_content .spec a.greenButton {
    font-size: 12px;
    display: block;
    text-align: center;
}
#addon_content .spec_insurance {
    float: right;
    width: 49%;
}
#addon_content .spec_insurance a.botAzu {
    display: block;
    width: 100%;
    padding: 2px 0;
    margin: 0;
    text-align: center;
}
#addon_content #addon_default .item {
    border-bottom-color: #e5e5e5;
}
#addon_content td.hotFot {
    width: 15%;
    table-layout: fixed;
    display: block;
    float: left;
}
#addon_content td.hotFot img {
    margin: 0 auto;
    display: block;
}
#addon_content td.hotInf {
    width: 42.5%;
    table-layout: fixed;
    display: block;
    float: left;
}
#addon_content td.boxPri {
    width: 42.5%;
    table-layout: fixed;
    display: block;
    float: left;
}
#addon_content td.boxPri ul.group {
    width: 100%;
}
#addon_content td.boxPri .ratefrom {
    width: 49%;
    float: left;
}
#addon_content td.boxPri .spec {
    float: right;
    width: 49%;
}
#addon_content li.img {
    width: 15%;
}
#addon_content li.img img {
    margin: 0 auto;
    float: none;
    display: block;
}
#addon_content .addon_top {
    background: #fff;
}
#addon_content .thCar {
    background: transparent !important;
}
#addon_content #divComplete td.right,
#addon_content .carsAddon .rates td {
    padding: 5px 0;
    line-height: 25px;
}

.formbox {
    background: transparent;
    border-color: #e5e5e5;
}
.formbox h2 {
    background: $color-principal;
    color: #fff;
}
.formbox .formForgot {
    border-top-color: #e5e5e5;
}

div#regSteps li {
    background: $color-principal;
}
div#regSteps li a,
div#regSteps li a:hover {
    color: #fff;
}
div#regSteps .current,
div#regSteps li.current a {
    background: $color-principal;
    color: #fff;
    font-size: 1em;
}
.ageLog #main .hotel-item,
.ageLog #main.sn-results .bus-item,
.ageLog #main.sn-results .carElement,
.ageLog #main.sn-results .flight-item,
.ageLog #main.sn-results .transfer-item,
.ageLog #main.tours-list .activity,
.ageLog .sn-results .pack-item {
    background: transparent;
    border-radius: 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
}
#chkOut.ageLog #container2 {
    padding: 0;
}

#login-form input {
    max-width: 100%;
}

body {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
}
div#container2 {
    width: 100%;
    margin: 0;
}
div#splashContent {
    margin-top: 0;
}
div#Content {
    margin: 0 auto;
    float: none;
    clear: both;
}

.mainContent {
    margin-left: 0px;
    margin-right: 0px;
    max-width: 1400px;
    margin: 0 auto;
}

.mainContent:after {
    content: "";
    display: table;
    clear: both;
}
#hsidebar + .home div#ptw-container,
.ageLog .home div#ptw-container {
    background: none;
    width: 100%;
    max-width: 400px;
}
.heroDescription,
.heroTitle {
    text-align: center;
    float: left;
    width: 100%;
}
.contentBox:after {
    content: "";
    display: table;
    clear: both;
}
.destinationBox {
    padding: 5px;
    margin-top: 20px;
}

.contentBox {
    max-width: 1400px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}
.contentBox:after,
.contentBox:before {
    content: " ";
    display: table;
    clear: both;
}
.heroDescription,
.heroTitle {
    float: none;
}
.pageIntroduction {
    background: $white;
    padding-top: 60px;
    padding-bottom: 60px;
}
.pageIntroduction .heroDescription,
.pageIntroduction .heroTitle {
    max-width: 900px;
    color: $color-headers;
    text-align: center;
    margin: 0 auto;
}
.pageIntroduction .heroTitle {
    font-size: 40px;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 15px;
}
.pageIntroduction .heroDescription {
    font-size: 16px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 15px;
}
.pageIntroduction .heroDescription:last-child {
    margin-bottom: 0;
}
.payment-methods {
    margin-top: 30px;
    padding: 44px 60px !important;
    justify-content: center;
    align-items: center;
}
.payment-methods .col-left,
.payment-methods .col-right {
    text-align: center;
}
.payment-methods p {
    font-size: 22px;
    line-height: 157%;
    letter-spacing: 2px;
    color: $color-obscuro;
    margin-top: 0;
    margin-bottom: 20px;
}
.payment-methods .logos-banks {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.payment-methods .logos-banks li {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}
@media (min-width: 768px) {
    .payment-methods .col-left,
    .payment-methods .col-right {
        display: table-cell;
        vertical-align: middle;
    }
    .payment-methods .col-left {
        width: 40%;
        padding-right: 15px;
    }
    .payment-methods p {
        margin-bottom: 0;
    }
}
// .pageContent {
//   padding-top: 80px;
//   padding-bottom: 80px;
// }
.pageContent .promosGroup:after,
.pageContent .promosGroup:before {
    content: " ";
    display: table;
    clear: both;
}
.pageContent .heroTitle {
    font-size: 32px;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 40px;
}
.pageContent .promoBox {
    float: none;
    margin: 0 auto 20px;
}
.pageContent .promoBox:last-child {
    margin-bottom: 0;
}
.pageContent #promoTres {
    margin-left: auto;
}
@media (min-width: 768px) {
    .pageContent .contentBox {
        max-width: 960px;
    }
    .pageContent .heroTitle {
        text-align: left;
    }
    .pageContent .promosGroup {
        margin-left: -10px;
        margin-right: -10px;
    }
}
@media (min-width: 992px) {
    .pageContent .contentBox {
        max-width: 1400px;
    }
    .pageContent .pb-col-6 {
        width: 50%;
        max-width: 100%;
    }
}
@media (min-width: 1400px) {
    .contentBox {
        padding-left: 0;
        padding-right: 0;
    }
}

div#Content {
    padding: 20px 0;
}
div#top {
    margin-left: 10px;
}
div#top span.label {
    display: inline-block;
}
div#left {
    width: 21%;
    background-color: #fff;
    margin: 0;
}
div#main {
    width: 79%;
    padding: 0 0 0 30px;
    margin: 0 !important;
}
#left h3 {
    padding: 10px;
}
#Content #summary h2,
#hsidebar h2,
#left #ctrl h2,
#left .banNom h4,
#left .sidbar h4,
#left h3,
#reserveinfo h2,
.booker-title,
.sidbar h3,
.sidebar-title {
    font-weight: 600;
    color: #fff;
}
.sidebar-title .close {
    text-indent: 0;
}
.sidbar .module {
    padding: 5px 0;
}
#left #wrapper .sidbar .module h4,
#left #wrapper .sidbar .module h4 a,
.sidbar .module h4,
.sidbar .module h4 a {
    font-weight: 600;
}
#left #wrapper .sidbar .module h4 a.info,
.sidbar .module h4 a.info {
    color: #fff !important;
    background: $color-principal;
    border-color: $color-principal;
}
#left .module li a {
    font-size: 12px;
    padding: 2px 6px;
    margin-right: 0;
}
#main h1 {
    font-size: 24px;
}
div#ConTit {
    border-top: none;
}
.list-best-rates {
    font-size: 13px;
}
.list-best-rates .section-title {
    padding: 0.75em 1em;
}

#HotelListContainer .hotel-item {
    padding: 2px;
    border: 1px solid #e5e5e5;
    margin-bottom: 15px !important;
}
#HotelListContainer .hotel-item .hotsec {
    margin-bottom: 0;
}
table.pack td.hotFot {
    padding: 5px;
}
#main h2.hotNam {
    font-family: $font-headers;
    font-size: 22px;
    font-weight: 600;
}
#main h2.hotNam a {
    color: $color-principal;
}
.HotPri {
    font-family: $font-principal;
    font-size: 22px;
    font-weight: 600;
    color: $color-principal;
}
.spec {
    font-size: 11px;
}
.hotDat > div > * {
    white-space: normal;
}
#HotelListContainer .hotel-item .roomsrow > td td {
    padding: 10px 0 2px;
}
table.rates .ratedet .padding a.botAzu {
    font-family: $font-principal;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 10px;
}
table.rates .ratedet .padding a.botAzu:hover {
    text-shadow: none;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.hotel-item a.sl {
    font-size: 12px;
    color: $color-principal;
    padding: 5px;
}
.hotel-item a.sl:hover {
    text-decoration: underline;
}
#HotelListContainer .hotel-item tr.flrow table.small > tbody > tr > td {
    padding: 12px 5px;
}
.fareFamily-selected .icon-info {
    color: $color-principal;
}
#detPop p.smallv {
    padding: 0;
}
.pager .firstlast,
.paginas .firstlast {
    color: #aaa;
}
.paginas a,
.paginas a:link,
.paginas a:visited {
    color: $color-principal;
}
.paginas a.currentPage {
    background: $color-principal !important;
    border-color: $color-principal;
}
#divIntesticial .collapsedLabel,
#divIntesticial .visibleLabel {
    padding: 5px;
}
.roomsContainer table.rates .noav.ratedet {
    font-size: 12px;
    font-weight: 600;
    color: red;
}
#divDisableCoupon,
#headList a,
#parTotalBottom,
#parTotalBottomTitle,
#sumTar td,
#tdTotalTrip2C1,
#tdTotalTrip2C2,
#tdTotalTrip2C3,
#top h1 span,
.bold,
.flightsCounter-total,
.hotelsCounter-total,
.smallv,
strong {
    font-family: $font-principal;
    font-weight: 400;
}
#Content.singlePageHotel div#ptw-container.ptw-horizontal-search {
    background: #fff;
    padding: 0;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#Content.singlePageHotel div#ptw-container.ptw-horizontal-search #ptw-content {
    min-height: auto;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
#Content.singlePageHotel div#ptw-container #ptw-engines,
#Content.singlePageHotel div#ptw-container #ptw-variants .ap_booker_variantGroup {
    padding-bottom: 1em;
}
#Content.singlePageHotel .hotel-heading-price-total {
    font-family: $font-principal;
    font-weight: 600;
    color: $color-principal;
}

#splash {
    max-width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#splash h1,
#splash h4 {
    color: #000;
}
#splash h1 {
    font-size: 18px;
}
#splash h2 {
    font-size: 32px;
    color: $color-principal;
}
#main div#resFlight .banNom {
    background: #f3f3f3;
}
#summary .item {
    border-top: none;
    border-bottom: 1px solid #e5e5e5;
}

div#Content {
    padding: 20px 0;
    //Tamaño pantalla más footer
    min-height: calc(100vh - (110px + 250px));
}
#cruiseContent,
#container2 #Content {
    padding: 20px 16px;
    width: 100%;
}
div#top {
    margin-left: 10px;
}
div#top span.label {
    display: inline-block;
}
div#left {
    width: 21%;
    background-color: #fff;
    margin: 0;
}
div#main {
    width: 79%;
    padding: 0 0 0 30px;
    margin: 0 !important;
}
#left h3 {
    padding: 10px;
}
#Content #summary h2,
#hsidebar h2,
#left #ctrl h2,
#left .banNom h4,
#left .sidbar h4,
#left h3,
#reserveinfo h2,
.booker-title,
.sidbar h3,
.sidebar-title {
    font-weight: 600;
    color: #fff;
}
.sidebar-title .close {
    text-indent: 0;
}
.sidbar .module {
    padding: 5px 0;
}
#left #wrapper .sidbar .module h4,
#left #wrapper .sidbar .module h4 a,
.sidbar .module h4,
.sidbar .module h4 a {
    font-weight: 600;
}
#left #wrapper .sidbar .module h4 a.info,
.sidbar .module h4 a.info {
    color: #fff !important;
    background: $color-principal;
    border-color: $color-principal;
}
#left .module li a {
    font-size: 12px;
    padding: 2px 6px;
    margin-right: 0;
}
#main h1 {
    font-size: 24px;
}
div#ConTit {
    border-top: none;
}
.list-best-rates {
    font-size: 13px;
}
.list-best-rates .section-title {
    padding: 0.75em 1em;
}
#HotelListContainer .hotel-item {
    padding: 2px;
    border: 1px solid #e5e5e5;
    margin-bottom: 15px !important;
}
#HotelListContainer .hotel-item .hotsec {
    margin-bottom: 0;
}
table.pack td.hotFot {
    padding: 5px;
}
#main h2.hotNam {
    font-family: $font-headers;
    font-size: 22px;
    font-weight: 600;
}
#main h2.hotNam a {
    color: $color-principal;
}
.HotPri {
    font-family: $font-principal;
    font-size: 22px;
    font-weight: 600;
    color: $color-principal;
}
.spec {
    font-size: 11px;
}
.hotDat > div > * {
    white-space: normal;
}
#HotelListContainer .hotel-item .roomsrow > td td {
    padding: 10px 0 2px;
}
table.rates .ratedet .padding a.botAzu {
    font-family: $font-principal;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 10px;
}
table.rates .ratedet .padding a.botAzu:hover {
    text-shadow: none;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.hotel-item a.sl {
    font-size: 12px;
    color: $color-principal;
    padding: 5px;
}
.hotel-item a.sl:hover {
    text-decoration: underline;
}
#HotelListContainer .hotel-item tr.flrow table.small > tbody > tr > td {
    padding: 12px 5px;
}
.fareFamily-selected .icon-info {
    color: $color-principal;
}
#detPop p.smallv {
    padding: 0;
}
.pager .firstlast,
.paginas .firstlast {
    color: #aaa;
}
.paginas a,
.paginas a:link,
.paginas a:visited {
    color: $color-principal;
}
.paginas a.currentPage {
    background: $color-principal !important;
    border-color: $color-principal;
}
#divIntesticial .collapsedLabel,
#divIntesticial .visibleLabel {
    padding: 5px;
}
.roomsContainer table.rates .noav.ratedet {
    font-size: 12px;
    font-weight: 600;
    color: red;
}
#divDisableCoupon,
#headList a,
#parTotalBottom,
#parTotalBottomTitle,
#sumTar td,
#tdTotalTrip2C1,
#tdTotalTrip2C2,
#tdTotalTrip2C3,
#top h1 span,
.bold,
.flightsCounter-total,
.hotelsCounter-total,
.smallv,
strong {
    font-family: $font-principal;
    font-weight: 400;
}
#Content.singlePageHotel div#ptw-container.ptw-horizontal-search {
    background: #fff;
    padding: 0;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#Content.singlePageHotel div#ptw-container.ptw-horizontal-search #ptw-content {
    min-height: auto;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
#Content.singlePageHotel div#ptw-container #ptw-engines,
#Content.singlePageHotel div#ptw-container #ptw-variants .ap_booker_variantGroup {
    padding-bottom: 1em;
}
#Content.singlePageHotel .hotel-heading-price-total {
    font-family: $font-principal;
    font-weight: 600;
    color: $color-principal;
}
#splash {
    max-width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#splash h1,
#splash h4 {
    color: #000;
}
#splash h1 {
    font-size: 18px;
}
#splash h2 {
    font-size: 32px;
    color: $color-principal;
}
#main div#resFlight .banNom {
    background: #f3f3f3;
}
#summary .item {
    border-top: none;
    border-bottom: 1px solid #e5e5e5;
}

#addon_container #addon_content li.ratefrom {
    margin-bottom: 5px;
}
#addon_container #addon_content li.morelink a {
    font-size: 12px;
    text-decoration: underline;
}
#addon_container #addon_content li.morelink a:hover {
    text-decoration: none;
}
#addon_container #addon_content div.mark {
    font-size: 12px;
}

#addon_container #addon_content .item span.added {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0;
}
#addon_container #addon_content .dialogWrap {
    margin-left: 0;
}
#addon_container #addon_content .item .litem,
#addon_container #addon_content .item h3.itemName,
#addon_container #addon_content .itemName a {
    font-size: 14px;
    font-weight: 600;
    color: $color-principal;
}
#addon_container #addon_content .blk {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    background-color: $color-secundario;
}
#cboxOverlay {
    background: #000;
}
div#colorbox {
    border: none;
}
#cboxLoadedContent {
    margin: 0 auto;
}
#activityPopup .footer p a {
    color: #fff;
}
#activityPopup .footer p a:hover {
    text-decoration: underline !important;
}

body#chkOut {
    background: #f5f5f5;
}
#chkOut #header {
    -webkit-box-shadow: none;
    box-shadow: none;
}
#chkOut #step3 .gray-container {
    display: inline-block;
    font-size: 11px;
}
#chkOut #step3 .gray-container i.asterisk:after {
    content: "*";
    font-style: normal;
    color: red;
}
#chkOut div.banres {
    float: none;
    clear: both;
}
.banNom .padRi.chfDetails-route {
    font-family: $font-principal;
}
#summary-agency {
    display: none;
}
.po-container .title .font-icons {
    color: $color-principal !important;
}
.t-pink {
    color: $color-principal;
}
.po-container.active,
.po-container:hover {
    border: 2px solid $color-principal;
}
.po-container.active .title {
    color: $color-principal !important;
    font-weight: 500 !important;
}
#chkOut #container2 {
    width: 100%;
}
.po-content {
    p {
        margin: 0.5em 0;
    }
}
.row.higlight {
    background-color: aliceblue;
}
.flex {
    display: flex;
}
.text-center {
    text-align: center;
}

.slider-inner ul li .titleSlide .titleOffer {
    font-family: $font-titulares;
}

#cars .carElement table {
    width: 100%;
}
.ageLog #container2 {
    padding: 0px;
}
#resultados-page .ffflightGroup .flight-itinerary {
    font-size: 11px;
}

.highlight {
    background-color: $color-highlight;
    // background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%23b0c7dd' fill-opacity='0.39' fill-rule='evenodd'/%3E%3C/svg%3E");
    h2 {
        color: $color-highlight-header;
    }
    p {
        color: $color-highlight-text;
    }
}
.highlight-2 {
    background-color: $color-highlight2;
    h2 {
        color: $color-highlight2-header;
    }
    p {
        color: $color-highlight2-text;
    }
}

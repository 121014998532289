.bookerBox {
    position: relative;
    .slideContainer {
        z-index: 1;
    }
    #ptw-container {
        position: relative;
        top: -65px;
        z-index: 2;
    }
}

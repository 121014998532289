//Slider
$color-cortinaSlider: $color-secundario;

//Bolitas
.controls-pager {
    position: relative;
    top: -38px;
    z-index: 2;
    padding-top: 0px;
}
.controls-pager a {
    height: 14px;
    width: 14px;
}
.controls-pager a.active {
    background-color: $color-principal;
    box-shadow:
        inset 0 1px 3px -1px $color-principal,
        0 1px 1px $white;
}

.ninja-slider ul {
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.ninja-slider .ns-img {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}
//Texto
div.slider-inner ul li .titleSlide {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 32px;
    width: 100%;
    max-width: 90%;
    left: unset;
    top: unset;
    right: unset;
    padding: 16px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
.slider-inner ul li .titleSlide .titleOffer {
    display: inline-flex;
    justify-content: center;
    text-align: center;
}

//Boton
.slider-inner ul li .titleSlide .titleBtn a.btnSlide {
    display: inline-flex;
    background-color: $color-secundario;
    border-radius: $borderRadius-boton;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}

$color-agencyBar-background: $color-obscuro;

//@import url("https://b2b2c.cdnpt.com/general/css/agencies.css");
div#agencyBar {
    top: 0;
    left: 0;
    height: auto;
    border-bottom: none;
    width: 100%;
    z-index: 10000;
    //Cambiar a position Fixed
    position: unset;
    background: $color-obscuro;

    margin: 0px;

    padding: 4px 16px;
}
//Ajustar el tmaño del padding de acuerdo al tamaño del AgencyBar
// div#agencyBar + header {
//     padding-top: 35px;
// }

#agencyBar,
#agencyBar a {
    color: $white;
}
//Ocultar nombre agencia

div#agencyBar .agencyName {
    display: none;
}

.agencyBarMenu {
    margin: 0px;
    padding: 0px;
    position: relative;
    list-style: none;
}
.agencyBar-inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: end;
    margin: auto;
}
.agencyBar-inner .inactiveAgent {
    order: 2;
}

div#agencyBar a {
    color: #fff;
}
div#agencyBar a:hover {
    text-decoration: none;
}
div#agencyBar .agencyName {
    float: left;
    margin: 7px 15px 0px;
    font-size: 16px;
    font-weight: bold;
}
div#agencyBar .agencyOption {
    -moz-border-radius: none;
    float: none;
    background: transparent;
    margin: 0px;
    padding: 0px;
    border: none;
    text-align: center;
    background: transparent;
    border-radius: 0;
    color: rgba(255, 255, 255, 0.8);
    border-color: #e5e5e5;
    text-shadow: none;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
div#agencyBar .agencyOption > a {
    font-weight: bold;
}
div#agencyBar .agencyOption .caret {
    opacity: 0.4;
    width: auto;
    height: auto;
    border: none;
    content: initial;
    text-indent: initial;
    display: inline;
    vertical-align: initial;
}
div#agencyBar .agencyOption .caret:after {
    width: 11px;
    height: 11px;
    margin-top: -2px;
}
div#agencyBar .agencyOption:hover .caret {
    opacity: 1;
}
div#agencyBar .agencyOption:hover .dropdown-menu {
    max-height: 900px;
}
div#agencyBar .agencyOption .dropdown-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
    position: absolute;
    top: auto;
    left: auto;
    float: none;
    background: $color-agencyBar-background;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    min-width: 0 !important;
}
div#agencyBar .agencyOption .dropdown-menu li {
    padding: 6px 3px;
}
div#agencyBar .agencyOption .dropdown-menu a {
    padding: 0;
    line-height: normal;
}
div#agencyBar .agencyOption .dropdown-menu a:hover {
    background: none;
    color: #000;
}
div#agencyBar .agencyOption a {
    font-size: 12px;
    text-decoration: none;
    color: #fff;
}
div#agencyBar .agentName {
    float: right;
    margin: 7px 15px;
    font-size: 12px;
    font-weight: normal;
}
div#agencyBar .inactiveAgent {
    color: #fff;
    margin-top: 0px;
    float: none;
    margin-right: 0px;
    font-weight: bold;
}
div#agencyBar .inactiveAgent .btnLogout {
    display: inline-flex;
    padding: 2px 16px;
    background: $color-principal;
    border-radius: 0;
    color: rgba(255, 255, 255, 0.8);
    border-color: #e5e5e5;
    text-shadow: none;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    padding: 4px 10px;
    margin-left: 20px;
    border: 0px solid $color-principal;

    color: #fff !important;
    border-radius: $borderRadius-boton;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
div#agencyBar .inactiveAgent .btnLogout:hover {
    background: $color-principal;
    color: $white;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    border: 0px solid $color-principal;
}
#login {
    float: none;
    max-width: 300px;
    padding: 15px 0 0 0;
    margin: 2em auto;
}
#login + #register,
#register {
    float: none;
    max-width: 300px;
    padding: 10px;
    margin: 2em auto;
    background: #fff;
    border-color: #e5e5e5;
    text-align: center;
}
#agencyBarMenu-check,
label.agencyBarMenu-label {
    display: none;
}
//CrossLogin
div#agencyBar .agencyBarMenu {
    display: none;
}
body#chkOut {
    margin-top: 0px;
}
#chkOut #header {
    margin: auto;
    width: 100%;
}

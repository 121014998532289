/*! Custom JC 20-jun-24 v3 */
@import "variables/fonts";
@import "variables/icons-mundo-joven";
@import "variables/variables";

@import "mixins/general";
@import "mixins/grid";

@import "mixins/layout-fix";
@import "mixins/text";
@import "mixins/links";
@import "mixins/buttons";

@import "mixins/agencyBar";
@import "mixins/header";
@import "mixins/footer";

@import "mixins/bookerBox";
@import "mixins/slider";

@import "mixins/responsive";
#ptw-container.ptw-horizontal-search #ap_booker_FlightPackage .ptw-fields-container .ptw-field, #ptw-container.ptw-horizontal-search #ap_booker_Hotel .ptw-fields-container .ptw-field, #ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container .ptw-field, #ptw-container.ptw-horizontal-search #ap_booker_Car .ptw-fields-container .ptw-field, #ptw-container.ptw-horizontal-search #ap_booker_Bus .ptw-fields-container .ptw-field {
    margin-right: 10px !important;
}

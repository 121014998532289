$icomoon-font-family: "icons-mundo-joven" !default;
$icomoon-font-path: "https://b2b2c.cdnpt.com/mundojoven/fonts" !default;

$icons-x: unquote('"\\e900"');
$icons-twitter: unquote('"\\e900"');
$icons-calendar_month: unquote('"\\e901"');
$icons-dark_mode: unquote('"\\e902"');
$icons-markunread: unquote('"\\e903"');
$icons-flight_land: unquote('"\\e904"');
$icons-flight_takeoff: unquote('"\\e905"');
$icons-language: unquote('"\\e906"');
$icons-room: unquote('"\\e907"');
$icons-mail_outline: unquote('"\\e908"');
$icons-person: unquote('"\\e909"');
$icons-peoples-two: unquote('"\\e90a"');
$icons-actividades: unquote('"\\e90b"');
$icons-blog: unquote('"\\e90c"');
$icons-cruceros: unquote('"\\e90d"');
$icons-estudios: unquote('"\\e90e"');
$icons-hoteles: unquote('"\\e90f"');
$icons-isic: unquote('"\\e910"');
$icons-llamada: unquote('"\\e911"');
$icons-ofertas: unquote('"\\e912"');
$icons-paquetes: unquote('"\\e913"');
$icons-parques: unquote('"\\e914"');
$icons-playas: unquote('"\\e915"');
$icons-seguros: unquote('"\\e916"');
$icons-sucursales: unquote('"\\e917"');
$icons-tours: unquote('"\\e918"');
$icons-vuelos: unquote('"\\e919"');
$icons-phone: unquote('"\\e91a"');
$icons-telephone: unquote('"\\e91a"');
$icons-contact: unquote('"\\e91a"');
$icons-support: unquote('"\\e91a"');
$icons-call: unquote('"\\e91a"');
$icons-whatsapp: unquote('"\\e91b"');
$icons-brand: unquote('"\\e91b"');
$icons-social: unquote('"\\e91b"');
$icons-instagram: unquote('"\\e91c"');
$icons-brand1: unquote('"\\e91c"');
$icons-social1: unquote('"\\e91c"');
$icons-facebook: unquote('"\\e91d"');
$icons-brand2: unquote('"\\e91d"');
$icons-social2: unquote('"\\e91d"');

@font-face {
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?y8qxcg");
    src:
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?y8qxcg#iefix") format("embedded-opentype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?y8qxcg") format("truetype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?y8qxcg") format("woff"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?y8qxcg##{$icomoon-font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.font-icons-mj {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color:#000;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icons-x {
    &:before {
        content: $icons-x;
    }
}
.icons-twitter {
    &:before {
        content: $icons-twitter;
    }
}
.icons-calendar_month {
    &:before {
        content: $icons-calendar_month;
    }
}
.icons-dark_mode {
    &:before {
        content: $icons-dark_mode;
    }
}
.icons-markunread {
    &:before {
        content: $icons-markunread;
    }
}
.icons-flight_land {
    &:before {
        content: $icons-flight_land;
    }
}
.icons-flight_takeoff {
    &:before {
        content: $icons-flight_takeoff;
    }
}
.icons-language {
    &:before {
        content: $icons-language;
    }
}
.icons-room {
    &:before {
        content: $icons-room;
    }
}
.icons-mail_outline {
    &:before {
        content: $icons-mail_outline;
    }
}
.icons-person {
    &:before {
        content: $icons-person;
    }
}
.icons-peoples-two {
    &:before {
        content: $icons-peoples-two;
    }
}
.icons-actividades {
    &:before {
        content: $icons-actividades;
    }
}
.icons-blog {
    &:before {
        content: $icons-blog;
    }
}
.icons-cruceros {
    &:before {
        content: $icons-cruceros;
    }
}
.icons-estudios {
    &:before {
        content: $icons-estudios;
    }
}
.icons-hoteles {
    &:before {
        content: $icons-hoteles;
    }
}
.icons-isic {
    &:before {
        content: $icons-isic;
    }
}
.icons-llamada {
    &:before {
        content: $icons-llamada;
    }
}
.icons-ofertas {
    &:before {
        content: $icons-ofertas;
    }
}
.icons-paquetes {
    &:before {
        content: $icons-paquetes;
    }
}
.icons-parques {
    &:before {
        content: $icons-parques;
    }
}
.icons-playas {
    &:before {
        content: $icons-playas;
    }
}
.icons-seguros {
    &:before {
        content: $icons-seguros;
    }
}
.icons-sucursales {
    &:before {
        content: $icons-sucursales;
    }
}
.icons-tours {
    &:before {
        content: $icons-tours;
    }
}
.icons-vuelos {
    &:before {
        content: $icons-vuelos;
    }
}
.icons-phone {
    &:before {
        content: $icons-phone;
    }
}
.icons-telephone {
    &:before {
        content: $icons-telephone;
    }
}
.icons-contact {
    &:before {
        content: $icons-contact;
    }
}
.icons-support {
    &:before {
        content: $icons-support;
    }
}
.icons-call {
    &:before {
        content: $icons-call;
    }
}
.icons-whatsapp {
    &:before {
        content: $icons-whatsapp;
    }
}
.icons-brand {
    &:before {
        content: $icons-brand;
    }
}
.icons-social {
    &:before {
        content: $icons-social;
    }
}
.icons-instagram {
    &:before {
        content: $icons-instagram;
    }
}
.icons-brand1 {
    &:before {
        content: $icons-brand1;
    }
}
.icons-social1 {
    &:before {
        content: $icons-social1;
    }
}
.icons-facebook {
    &:before {
        content: $icons-facebook;
    }
}
.icons-brand2 {
    &:before {
        content: $icons-brand2;
    }
}
.icons-social2 {
    &:before {
        content: $icons-social2;
    }
}

// Pantalla de Login
#login {
    float: none;
    width: 100% !important;
    max-width: 450px !important;
    padding: 0px 0 0 0;
    margin: 2em auto;
    @media (max-width: 767.98px) {
        display: flex;
        flex-direction: column;
        #login-form,
        #login-info {
            float: none;
            width: 100% !important;
            div {
                border-right: 0px solid white !important;
            }
        }
    }

    .formRow input {
        height: 55px;
        width: 100%;
    }
}
div#main-login {
    padding: 1px;
    max-width: 100%;
    @media (max-width: 767.98px) {
    }
}
//Help layout

.help-page {
    #Content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 32px;
    }
    #Content #main {
        display: flex;
        flex-wrap: wrap;
        margin: 0px;
        max-width: calc(80% - 32px);
        h1 {
            width: 100%;
        }
        @media (max-width: 992px) {
            max-width: calc(80% - 32px);
            margin: 0px !important;
        }
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
    .reslinks,
    #secTitle {
        width: 100%;
    }
    #helpleft {
        width: 100%;
        max-width: 20%;
        margin: 0px;
        @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
    }
    #itinerary {
        width: 77%;
    }
    #helpleft .hotsidbar {
        width: 100%;
        margin: 0px;
    }
}

.btn-choose-hotel .botAzu.btn.btn-success {
    @media (max-width: 768px) {
        width: 100%;
    }
}

.btn,
.btn.btn-default,
a.btn,
a.controls {
    background: $color-principal;
    border-radius: $borderRadius-boton;
    color: rgba(255, 255, 255, 0.8);
    border-color: #e5e5e5;
    text-shadow: none;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
.btn.btn-default:active,
.btn.btn-default:hover,
.btn:hover,
a.btn:hover,
a.controls:active,
a.controls:hover {
    background: $color-principal;
    color: white;
    border-color: #e5e5e5;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.2);
    text-decoration: none;
}
a.controls.prevBtn {
    border-radius: 4px 0 0 4px;
}
a.controls.nextBtn {
    border-radius: 0 4px 4px 0;
}
#btnName,
#innerSel #continueBtn,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn,
.botAzu,
.botAzu.mSprite,
.botAzu.mSprite:visited,
.botAzu:visited,
.btn-primary,
.btn-primary:visited,
.btn-success,
.btn-success:visited,
.btn.btn-primary,
.btnOfferts a,
.bttn-blue,
.bttn-blue:visited,
.bttn-green,
.bttn-green:visited,
.greenButton,
a.ap_circuitGo.btn,
a.botAzu,
a.btn-success,
a.greenButton,
div#ptw-container .ptw-submit-btn,
div#ptw-container .ptw-submit-btn,
input#resSta,
input.but,
input.greenButton {
    font-family: $font-boton;
    background: $color-principal;
    border-radius: $borderRadius-boton;
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid $color-principal;
    text-shadow: none;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
a.greenButton.whiteText {
    background-color: $color-boton;
    color: $color-boton-texto;
    border: $color-boton-border;
    &:hover {
        background-color: $color-boton-hover;
        color: $color-boton-texto-hover !important;
        border: $color-boton-border-hover;
    }
}

a.greenButton.whiteText {
    color: $color-boton-texto !important;
}
.keepPack a.greenButton {
    font-size: 15px;
    padding: 7px 12px;
}
.keepPack a.greenButton:hover {
    text-decoration: none;
}
input#btnName {
    padding: 3px 9px;
}
#btnName:hover,
#ptw-offers .ptw-offer-item > a .active.ptw-submit-btn,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:active,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:focus,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:hover,
.botAzu.active,
.botAzu.disabled,
.botAzu.mSprite:active,
.botAzu.mSprite:focus,
.botAzu.mSprite:hover,
.botAzu:active,
.botAzu:focus,
.botAzu:hover,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary[disabled],
.btn-success:hover,
.btn.btn-primary:hover,
.btnOfferts a.active,
.btnOfferts a:active,
.btnOfferts a:focus,
.btnOfferts a:hover,
.bttn-blue:focus,
.bttn-blue:hover,
.bttn-green:hover,
a.ap_circuitGo.btn:hover,
a.botAzu:hover,
a.btn-success:hover,
a.bttn-blue:visited,
a.greenButton:hover,
div#ptw-container .active.ptw-submit-btn,
div#ptw-container .ptw-submit-btn:active,
div#ptw-container .ptw-submit-btn:focus,
div#ptw-container .ptw-submit-btn:hover,
input.but:hover,
input.greenButton:hover {
    background: $color-principal;
    color: white;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid $color-principal;
    text-decoration: none;
}

.stat-medium a.btn {
    width: auto;
    display: block;
    margin-top: 10px;
    margin-bottom: 9px;
    background: $color-principal;
    border-radius: $borderRadius-boton;
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid $color-principal;
    text-shadow: none;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
.stat-medium a.btn:hover {
    background: $color-principal;
    color: white;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid $color-principal;
    text-decoration: none;
}
#addon_content .currentLink {
    background: $color-principal !important;
}

#addon_content #tourAddon #tabs li a {
    min-height: 27px;
}
#addon_content #tourAddon #tabs li a:hover {
    text-decoration: none;
}
#addon_content div.mark {
    width: 94%;
    margin-left: 3%;
    text-align: center;
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#addon_content div.mark a {
    color: inherit;
}
.item .litem,
.item h3.itemName {
    color: $color-principal;
}
.item .data .name {
    margin: 0;
    float: left;
    width: 50%;
}
.item .data .price {
    margin: 0;
    float: left;
    width: 50%;
    display: block;
}
.item .data .price ul {
    width: 100%;
}
.item .data .price li.ratefrom {
    margin: 0 !important;
    width: 49%;
    float: left;
}
.item .data .price li.ratefrom + li {
    width: 49%;
    float: right;
}
.item .data .price a.botAzu {
    width: auto;
    display: block;
    margin-top: 0;
    text-align: center;
    font-weight: normal;
    font-size: 11px;
}
.item span.added {
    width: 100%;
}
#main #addon_content td.boxPri {
    padding: 0;
}
#addon_menu .defaultItem .addonTab,
#addon_menu a {
    border: 1px solid #e5e5e5;
    background: #fff;
}
#addon_menu .current a,
#addon_menu .current a:hover {
    border-color: #e5e5e5;
    background: #efefef;
    background: -webkit-gradient(linear, left top, right top, from(#efefef), to(rgba(239, 239, 239, 0)));
    background: -webkit-linear-gradient(left, #efefef, rgba(239, 239, 239, 0));
    background: -o-linear-gradient(left, #efefef, rgba(239, 239, 239, 0));
    background: linear-gradient(90deg, #efefef, rgba(239, 239, 239, 0));
    color: $color-principal;
    font-weight: bold;
    border-right: 1px solid white;
}
#addon_menu img.addonicon {
    display: none;
    background: none;
}
#addon_menu #carTab a:before,
#addon_menu #insuranceTab a:before,
#addon_menu #tourTab a:before,
#addon_menu #transferTab a:before {
    font-family: "ptw-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    line-height: 50px;
    height: 40px;
    color: #e5e5e5;
}
#addon_menu #carTab.current a:before,
#addon_menu #insuranceTab.current a:before,
#addon_menu #tourTab.current a:before,
#addon_menu #transferTab.current a:before {
    color: $color-principal;
}
#addon_menu #transferTab a:before {
    font-size: 66px;
    content: "\e616";
}
#addon_menu #carTab a:before {
    font-size: 51px;
    content: "\e006";
}
#addon_menu #tourTab a:before {
    font-size: 41px;
    content: "\e61c";
}
#addon_menu #insuranceTab a:before {
    font-size: 48px;
    content: "\26e8";
}
.td-alert-info a {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
#main .ban {
    border: 1px solid #e5e5e5;
    background: #fff;
}
#resFlight tr.banNom td {
    background: #fff;
}
#activityAddon #tabs a,
#activityAddon #tabs span,
#activityPopup #tabs a,
#activityPopup #tabs span {
    border-color: #fff;
    border-bottom-color: #e5e5e5;
}
#activityAddon #tabs a:hover,
#activityAddon #tabs span:hover,
#activityPopup #tabs a:hover,
#activityPopup #tabs span:hover {
    text-decoration: none;
    background: #efefef;
    border-color: #efefef;
    border-bottom-color: #e5e5e5;
}
#activityAddon ul#tabs,
#activityPopup ul#tabs {
    background: #fff;
    border-color: #e5e5e5;
}
.addon h3 {
    color: $color-principal;
    border-bottom-color: #e5e5e5;
}
#cboxOverlay {
    background: $color-principal;
}
#cboxBottomCenter,
#cboxBottomLeft,
#cboxBottomRight,
#cboxMiddleLeft,
#cboxMiddleRight,
#cboxTopCenter,
#cboxTopLeft,
#cboxTopRight {
    background: none;
    width: 0;
}
#cboxContent {
    background: white;
    display: block;
    border-radius: $borderRadius-boton;
    border: 1px solid #e5e5e5;
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.6);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.6);
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
#cboxContent .selectDates table {
    min-width: 240px;
}
#cboxContent input#calDeparturetxtDate,
#cboxContent input#calReturntxtDate,
#cboxContent input#txtAirportFrom,
#cboxContent input#txtAirportTo {
    border-color: #e5e5e5;
    margin-top: 0;
}
.bd #activityPopup .closelink a {
    color: white;
}
.bd #activityPopup img {
    max-width: 100%;
}
div#colorbox {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
#activityPopup {
    width: auto !important;
}
#activityPopup #head {
    background: #fff;
}
#activityPopup #head h1,
#activityPopup #head h1.certificado {
    padding: 15px 5px 25px 10px;
    margin: 0;
}
#activityPopup #main {
    width: auto;
}
#activityPopup #container2,
#activityPopup #Content {
    width: 100% !important;
    overflow: hidden;
    padding-bottom: 10px !important;
}
#activityPopup ul#tabs {
    background: #fff;
}
#activityPopup #ActSidebar {
    font-size: 12px;
}
#activityPopup .closelink {
    background: none;
}
#activityPopup .closelink a {
    padding: 8px 34px 8px 8px;
    background-position: 90px center;
}
#activityPopup #imageGalleryContainer {
    width: auto;
    max-width: 100%;
}
#activityPopup #carousel {
    background-color: #fff;
    margin-top: 30px;
    border-color: #fff;
}
#activityPopup #Content .ActivOpt {
    width: auto !important;
    border-radius: $borderRadius-boton;
    border: none;
    border-top: 1px solid #e5e5e5;
    padding-top: 10px;
    text-shadow: none;
    background: none;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
#activityPopup #Content #optionList,
#activityPopup #Content table.actDates {
    width: auto !important;
}
#activityPopup select {
    font-size: 13px;
}
#activityPopup #pax {
    border: 1px solid #e5e5e5;
    background: #fff;
    border-radius: $borderRadius-boton;
    padding: 5px 15px;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
.banNomBtn {
    background: none;
}
.banNomBtn input.greenButton {
    padding: 5px 33px;
}
#mapDiv {
    font-size: 15px;
}
#mapDiv #map_canvas {
    width: 100% !important;
}
table.actDates {
    border-top: none;
}
table.actDates td,
table.actDates td.actrates,
table.actDates th,
table.actDates th.actrates {
    background: #fff;
}
#activDetail tr.selected td {
    background: #efefef;
}
.ActOptions li.selected .pricenote {
    background-color: #efefef;
}
span.first-child button {
    background: none;
    height: 20px;
    width: 20px;
    margin: 0;
    text-align: right;
    position: relative;
    text-indent: 0;
}
span.first-child button:before {
    font-family: "ptw-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e612";
    font-size: 24px;
    color: $color-principal;
    z-index: 0;
}
ul.serviceList {
    font-size: 13px;
}
ul.serviceList p {
    margin-bottom: 0.5em;
}
ul.serviceList:last-of-type {
    padding-bottom: 30px;
}
.carousel ol.carousel-content li:hover {
    border-color: $color-principal;
}
.carousel .carousel-nav input {
    bottom: 4px;
}
#ActTop + table + table.intro,
#ActTop + table.intro {
    width: auto;
}
body#chkOut {
    background: #fff;
}
#chkOut #Content {
    clear: both;
    width: 760px;
}
#chkOut #header:after {
    content: "";
    display: table;
    clear: both;
}
#chkOut #container2 {
    border-color: #e5e5e5;
}
#chkOut #divConfirmationReadUser,
#chkOut #divPassengers,
#chkOut #step3,
#chkOut #step3 .boxleft,
#chkOut .conInf,
#chkOut h1,
#chkOut h3 {
    background: none;
    border: none;
    border-top: 1px solid #e5e5e5;
}
#chkOut div.banres {
    border-top: 1px solid #e5e5e5;
    background: none;
}
#chkOut .stepsNumbers_Active {
    background: $color-principal;
}
#chkOut .steps li.current {
    color: $color-principal;
}
#chkOut #divPayNow {
    padding-top: 0;
}
#chkOut #divPayNow ol {
    border: 1px solid #e5e5e5;
    background: #fff;
    margin-top: 0;
}
#chkOut tr.mark td {
    background: #efefef;
}
#chkOut .bluebox {
    background: transparent;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}
#chkOut #step3 .boxleft {
    border: none;
}
#chkOut .boxleft h3 {
    color: $color-principal;
    margin-top: 0;
    padding-top: 10px;
}
#chkOut .boxleft .banNom,
#chkOut .rateSummary {
    border: 1px solid #e5e5e5;
    background: #fff;
    margin-bottom: 10px;
}
#chkOut .boxleft h1,
#chkOut .boxleft h2 {
    color: $color-principal;
}
#chkOut .floatright {
    background: #fff;
}
#chkOut .floatright h4 {
    background: rgba(94, 106, 113, 0.9);
    color: rgba(255, 255, 255, 0.8);
}
#chkOut .floatright td.top {
    border-top-color: #e5e5e5;
}
.higLit,
ol li strong,
table.flight p.small span.small {
    color: $color-principal;
}
td.ret,
td.top,
tr.ret td {
    border-top-color: #e5e5e5;
}
#chkOut #step3 {
    border-top: none;
}
li#step4.step-Finalizar {
    display: none;
}

#offersContainer ul {
    padding: 0;
    margin: 0;
}
.hotel-offer-item {
    list-style: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
.hotel-offer-item .btn.btn-primary {
    padding: 6px 10px;
    width: auto;
    display: inline-block;
    margin-top: 14px;
}
.hotel-offer-item .label-cont {
    position: relative;
    width: 98%;
    margin: 0 1%;
    display: block;
    background: rgba(145, 69, 154, 0.3);
    padding: 0.5em 1em;
    margin-top: -2.4em;
    color: #fff;
    font-size: 1.2em;
    height: 2.4em;
    overflow: hidden;
}
.hotel-offer-item .hotel-pic {
    margin: 0;
}
.hotel-offer-item .hotel-pic {
    display: block;
    width: 98%;
    margin: 0 1%;
}
.hotel-offer-item .hotel-title {
    display: block;
    margin: 0 1%;
    background: #fff;
    padding: 10px 2% 0;
    width: 98%;
}
.hotel-offer-item .hotel-title br {
    display: none;
}
.hotel-offer-item .hotel-offer-info {
    display: block;
    margin: 0;
    background: #fff;
    width: 98%;
    margin: 0 1%;
    padding: 10px 2%;
    color: rgba(0, 0, 0, 0.7);
    line-height: 170%;
}
.hotel-offer-item .hotel-offer-info > * {
    display: block;
}
.hotel-offer-item .hotel-offer-info .price {
    font-size: 1.4em;
}
.hotel-offer-item .hotel-offer-info .note {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.5);
}

.goldBut,
.signin,
.submitButton,
a.goldBut,
input.submitButton {
    background: $color-principal;
    border-radius: $borderRadius-boton;
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid $color-principal;
    text-shadow: none;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0),
        0 1px 2px rgba(0, 0, 0, 0);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-border-radius: $borderRadius-boton;
    -moz-border-radius: $borderRadius-boton;
    -ms-border-radius: $borderRadius-boton;
    -o-border-radius: $borderRadius-boton;
}
.goldBut:hover,
.signin:hover,
.submitButton:hover,
a.goldBut:hover,
input.submitButton:hover {
    background: $color-principal;
    color: white;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid $color-principal;
    text-decoration: none;
    color: #fff;
    text-decoration: none;
}
#register a:visited:hover,
a.goldBut:hover {
    color: #fff;
}
#register a,
#register a:hover,
#register a:visited {
    color: #fff;
}

#addon_container #addon_menu li.current a {
    font-weight: 600;
    color: $color-boton-texto;
    background: $color-boton;
    border-left-color: #ddd;
    border-bottom-color: #ccc;
}
#addon_container #addon_menu a {
    font-size: 12px;
    color: #777;
    background: #f3f3f3;
}
#addon_container #addon_menu a:hover {
    color: #000;
    background: #fff;
}
#addon_container #addon_menu #carTab a:before,
#addon_container #addon_menu #insuranceTab a:before,
#addon_container #addon_menu #tourTab a:before,
#addon_container #addon_menu #transferTab a:before {
    color: $color-principal;
}

#addon_container #addon_content a.botAzu {
    width: auto;
    font-size: 12px;
    color: $color-boton-texto;
    text-shadow: none;
    background: $color-boton;
    padding: 6px 10px;
    width: 100%;
    border: $color-boton-border;
    border-radius: $borderRadius-boton;
}
#addon_container #addon_content a.botAzu:hover {
    color: $color-boton-texto-hover;
    background: $color-boton-hover;
    border: $color-boton-border-hover;
}

#btnName,
#innerSel #continueBtn,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn,
.botAzu,
.botAzu.mSprite,
.botAzu.mSprite:visited,
.botAzu:visited,
.btn-primary,
.btn-primary:visited,
.btn-success,
.btn-success:visited,
.btn.btn-primary,
.btnOfferts a,
.bttn-blue,
.bttn-blue:visited,
.bttn-green,
.bttn-green:visited,
.greenButton,
a.ap_circuitGo.btn,
a.botAzu,
a.btn-success,
a.greenButton,
input#resSta,
input.but,
input.greenButton {
    color: $color-boton-texto;
    background: $color-boton;
    border: $color-boton-border;
    border-radius: $borderRadius-boton;
    font-family: $font-boton;
    font-weight: $fontWeight-boton;
}
#btnName:hover,
#ptw-offers .ptw-offer-item > a .active.ptw-submit-btn,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:active,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:focus,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:hover,
.botAzu.active,
.botAzu.disabled,
.botAzu.mSprite:active,
.botAzu.mSprite:focus,
.botAzu.mSprite:hover,
.botAzu:active,
.botAzu:focus,
.botAzu:hover,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary[disabled],
.btn-success:hover,
.btn.btn-primary:hover,
.btnOfferts a.active,
.btnOfferts a:active,
.btnOfferts a:focus,
.btnOfferts a:hover,
.bttn-blue:focus,
.bttn-blue:hover,
.bttn-green:hover,
a.ap_circuitGo.btn:hover,
a.botAzu:hover,
a.btn-success:hover,
a.bttn-blue:visited,
a.greenButton:hover,
input.but:hover,
input.greenButton:hover {
    color: $color-boton-texto-hover;
    background: $color-boton-hover;
    border: $color-boton-border-hover;
}

.button-holder .btn {
    font-family: $font-boton;
    font-weight: 100;
    padding: 8px 10px;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.button-holder .btn:hover {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#Content.singlePageHotel #menu-container li {
    font-weight: $fontWeight-boton;
    font-family: $font-boton-soft;

    color: $color-boton-soft-texto;
    background: $color-boton-soft;
    border: $color-boton-soft-border;
    cursor: pointer;
    &:hover {
        color: $color-boton-soft-texto-hover;
        background: $color-boton-soft-hover;
        border: $color-boton-soft-border-hover;
    }
}

.button-holder .btn {
    font-family: $font-boton;
    font-weight: 100;
    padding: 8px 10px;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.button-holder .btn:hover {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#Content.singlePageHotel #menu-container li {
    font-weight: $fontWeight-boton;
    font-family: $font-boton-soft;

    color: $color-boton-soft-texto;
    background: $color-boton-soft;
    border: $color-boton-soft-border;
    cursor: pointer;
    &:hover {
        color: $color-boton-soft-texto-hover;
        background: $color-boton-soft-hover;
        border: $color-boton-soft-border-hover;
    }
}

#addon_content a.botAzu,
#addon_default a.botAzu,
.resumen-checkout #summary.checkout-summary .summary-container .greenButton,
#addon_content .spec a.greenButton {
    font-family: $font-boton;
    font-weight: $fontWeight-boton;
    font-size: 16px;
    text-transform: none;
    border-radius: $borderRadius-boton;
}

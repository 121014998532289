//Links
#titSea a:focus,
.travDat a .higLit,
.travDat a:visited .higLit,
a,
a.small,
a:visited,
div.agents a:focus,
div.agents a:visited {
    color: $color-principal;
}

#ameLis a.cloWha,
#divConfirmHelp a.cloWha,
#divCreditHelp a.cloWha,
#divDisableCoupon a.cloWha,
#divInsuranceNote a.cloWha,
#DivPromotional a.cloWha,
#divValidResidents a.cloWha,
#WhaIs a.cloWha {
    color: $color-principal;
    text-decoration: none;
}
#divPromotions a.cloWha {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}
#divPromotions a.cloWha:hover {
    color: white;
}
#cajaErr a:hover,
#tblReservations a:hover,
#titSea a:hover,
.hotsidbar li a:visited:hover,
a.imppt:hover,
a.sl:hover,
a:hover,
a:visited:hover,
tr.hiLits a:hover,
tr.subtitle th,
ul.ex a.imptt:hover {
    color: $color-principal;
}
